@import "sass-lib";
@media only screen and (max-width:1023px){

	.wrapper.new{ width: auto; margin: 0 20px; }
	.wrapper1360{ width: auto; margin: 0 20px; }
	.btn-copy{height: 43px; font-size: 13px;}
	header.new{
		.line:before{
			width:300px;
		}
		.left-header{ 
			img{ width: 300px; }
		}
		.right-header{
			img{ width: 60px; }
		}
	}
	.box-home.new{
		margin: 0 ; position: relative; padding-top: 250px;
		.list-box{
			width: 50%; padding: 0 15px;
			&.reksaabs{
				position: absolute; right: 0; top: 250px; bottom: 55px; 
				.btn-knj{
					position: absolute; bottom: 0; 
				}
			}
			&.loginabs{
			 	position: absolute; left: 0; top: 0; width: 100%;  float: none;
			 	.login.new{
			 		box-shadow: none;
			 		span{ display: none; }
			 		form{ @include afterclear; }
			 		.row{
			 			float: left; width: 33.333%; padding: 0 10px; @include boxSizing(border-box);
			 			&:last-child{
			 				padding-top:25px;
			 			}
			 		}
			 		&.forgot.new{
			 			.left{
			 				width: 100px;
			 			}
			 			.right{
			 				width: 200px;
			 			}
			 			.row{
			 				width: 25%;
			 			}
			 			span{ display: block; }
			 		}
			 	}
			 }
		}
	}
	.reksa-home{
		position: absolute; right: 0; left: 0; top: 0; bottom: 0;

	}
	.wrap-dana.new{
		position: absolute; left: 0; top: 50px; right: 15px; bottom: 55px; height: auto;
	}
	.middle-content{
		.wrapper{ width: auto; margin: 0 20px; }
		input[type="text"], input[type="password"], input[type="email"], input[type="file"], select, textarea{font-size: 12px; height: 43px;}
		@include placeholder{font-size: 12px;}
		.title-form{
			font-size: 14px;
		}
		.textstyle{
			&:before{top: 11px;}
			.noted{font-size: 13px; height: 43px; line-height: 43px;}
		}
		.input_file{
			span{font-size: 14px;}
		}
		.ty{
			font-size: 40px; margin-bottom: 20px;
		}
		.desc{
			width: 500px; height: 185px;
			p{ font-size: 18px; line-height: 25px; }
		}
		.images{
			margin: 0 -20px;
			img{  height: 100vh; object-fit: cover; }
		}
		.opt{font-size: 12px; line-height: 14px;}
	}
	.row_list{
        .col{
        	@include boxSizing(border-box);
            &.col_sml_full{width: 100%;}
            &.col_sml_8{width: 80%;}
            &.col_sml_7{width: 70%;}
            &.col_sml_6{width: 60%;}
            &.col_sml_55{width: 55%;}
            &.col_sml_5{width: 50%;}
            &.col_sml_45{width: 45%;}
            &.col_sml_4{width: 40%;}
            &.col_sml_35{width: 35%;}
            &.col_sml_33{width: 33.3333333333333%;}
            &.col_sml_3{width: 30%;}
            &.col_sml_25{width: 25%;}
            &.col_sml_2{width: 20%;}
            &.col_sml_16{width: 16.66666666666666%;}
            &.col_sml_1{width: 10%;}
        }
    }
    .data-table-list{

    	tr,th,td{ font-size: 12px; line-height:16px;  }
		/*tbody{*/
			tr{
				th{padding-left: 10px; padding-right: 10px;}
	            /*th, td{
	                &:first-child{width: 45%;}
	                &:nth-child(2){width: 15%;}
	                &:nth-child(3){width: 10%;}
	                &:nth-child(4){width: 10%;}
	                &:nth-child(5){width: 10%;}
	                &:nth-child(6){width: 10%;}
	            }*/
			}
		/*}*/

		&.fundhome{
			tr{
				th, td{
	                &:first-child{width: 45%;}
	                &:nth-child(2){width: 15%;}
	                &:nth-child(3){width: 10%;}
	                &:nth-child(4){width: 10%;}
	                &:nth-child(5){width: 10%;}
	                &:nth-child(6){width: 10%;}
	            }
			}
		}
		&.fundreturn{
			tr{
				th, td{
	                &:first-child{width: 45%;}
	                &:nth-child(2){width: 15%;}
	                &:nth-child(3){width: 10%;}
	                &:nth-child(4){width: 10%;}
	                &:nth-child(5){width: 10%;}
	                &:nth-child(6){width: 10%;}
	            }
			}
		}
		&.freeze-table{
			.hide_mid{display: none !important;}
			tbody{
	            display: block; width: 100%; overflow: auto; height: 300px; 
	        }
		}
	}
	footer.new{
		padding: 20px 0;
		.left{
			float: none; width: 100%; display: block; margin: auto; text-align: center; margin-bottom: 20px;
			.cs{
				width: 220px;
				span{  }
				img{ width: 35px; }
			}
			.wa{
				width: 220px;
				img{ width: 35px; }
			}
		}
		.right{
			float: none; text-align: center; width: 100%; margin: auto; display: block;
		}
	}
}